// src/components/home/HeroSection/HeroSection.jsx
import React from 'react';
import './HeroSection.scss';
import heroCasinoImage from '../../../assets/images/hero-casino.webp';
import { Link } from 'react-router-dom';
const HeroSection = () => {
  const scrollToGame = () => {
    document.getElementById('game').scrollIntoView({ behavior: 'smooth' });
  };
  
  return (
    <section className="hero-section">
      <div className="container">
        <div className="hero-section__content">
          <div className="hero-section__text">
            <h1 className="hero-section__title">
              <span>THE BEST FREE</span>
              <span>SOCIAL <span className="highlight">CASINO GAME</span></span>
              <span>Play today.</span>
            </h1>
            <p className="hero-section__subtitle">
            Welcome to FreeSpinMaple- Your gold standard destination for immersive social free casino gaming. 
              Enjoy limitless hours of entertainment with our great selection of video games, beautiful pix, 
              and engaging social functions. No real cash playing, simply pure entertainment!
            </p>
            <div className="hero-section__buttons">
              <button className="hero-section__button hero-section__button--play" onClick={scrollToGame}>
                <span>▶</span>
                START YOUR JOURNEY
              </button>
              <Link to='/contact' className="hero-section__button">
                JOIN OUR COMMUNITY
              </Link>
            </div>
          </div>
          <div className="hero-section__image">
            <img src={heroCasinoImage} alt="Casino games illustration" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;