// src/components/home/FaqSection/FaqSection.jsx
import React, { useState } from 'react';
import './FaqSection.scss';

const FaqSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqItems = [
    {
      id: '01',
      question: 'What video games can I play?',
      answer: 'We provide a variety of exciting games including Jackpot Pearl, Max the Winner, Jackpot Multi, Jelly Hunter, Big Max Door, and Hidden Treasures of Rome. Each game features specific themes and gameplay mechanics for endless enjoyment.'
    },
    {
      id: '02',
      question: 'Is it free to play?',
      answer: 'Yes! All our games are completely free to play. You\'ll receive virtual credits to enjoy our games without ever having to spend real money. This is solely for entertainment purposes.'
    },
    {
      id: '03',
      question: 'How do I get started?',
      answer: 'Simply click on the "Play Now" button on the top of the webpage to create your free account. Once registered, you will receive virtual credits and can start playing immediately!'
    },
    {
      id: '04',
      question: 'How do I earn more virtual credits?',
      answer: 'You can earn additional virtual credits through daily login bonuses, completing achievements, participating in special events, and through our regular promotional offers.'
    },
    {
      id: '05',
      question: 'Is the platform secure?',
      answer: 'Absolutely! We use advanced encryption technology to protect your information. Since we don\'t handle real money transactions, you can play with complete peace of mind.'
    },
    {
      id: '06',
      question: 'Can I play on mobile devices?',
      answer: 'Yes! Our platform is fully optimized for mobile play. You can enjoy all our games on your phone or tablet with the same great features and smooth gameplay experience.'
    }
  ];

  const toggleQuestion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="faq-section">
      <div className="container">
        <div className="faq-section__header">
          <h2 className="faq-section__title">Frequently Asked Questions</h2>
          <p className="faq-section__description">
            Have questions? We've got answers! Check out our most commonly asked questions about our free-to-play gaming platform.
          </p>
        </div>

        <div className="faq-section__questions">
          <div className="faq-section__grid">
            {faqItems.map((item, index) => (
              <div
                key={item.id}
                className={`faq-section__item ${openIndex === index ? 'faq-section__item--active' : ''}`}
                onClick={() => toggleQuestion(index)}
              >
                <div className="faq-section__question">
                  <span className="faq-section__number">{item.id}.</span>
                  <h3>{item.question}</h3>
                </div>
                <div className="faq-section__answer">
                  <p>{item.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;