// src/pages/Privacy/Privacy.jsx
import '../LegalPage.scss';
import SEO from '../../components/common/SEO';

const Privacy = () => {
  return (
   <>
    <SEO 
        title="Privacy Policy"
        description="Privacy Policy and data handling practices at Maple Luck Spins CA social casino"
      />
    <section className="legal-page">
      <div className="container">
        <div className="legal-page__content">
          <h1 className="legal-page__title">Privacy Policy</h1>
          <div className="legal-page__text">
            <p>Last updated: March 28, 2024</p>
            
            <section className="legal-page__section">
              <h2>1. Introduction</h2>
              <p>
                Welcome to FreeSpinMaple. We are dedicated to shielding your privacy and making sure the safety of your personal facts. 
                This Privacy Policy explains how we acquire, use, divulge and shield your information while you operate our social casino gaming platform.
              </p>
            </section>

            <section className="legal-page__section">
              <h2>2. Information We Collect</h2>
              <p>
                To provide you with our gaming services, we gather:
              </p>
              <ul>
                <li>Basic account records (username, e mail, age verification)</li>
                <li>Gaming interest and preferences</li>
                <li>Technical records (tool info, IP deal with)</li>
                <li>Communication possibilities</li>
                <li>Virtual currency transaction data</li>
              </ul>
            </section>

            <section className="legal-page__section">
              <h2>3. Use of Information</h2>
              <p>
                Your information helps us:
              </p>
              <ul>
                <li>Deliver and enhance our gaming services</li>
                <li>Personalize your experience</li>
                <li>Process digital currency transactions</li>
                <li>Maintain platform security</li>
                <li>Communicate essential updates</li>
                <li>Provide customer support</li>
              </ul>
            </section>

            <section className="legal-page__section">
              <h2>4. Data Security</h2>
              <p>
                We protect your data through:
              </p>
              <ul>
                <li>Advanced encryption protocols</li>
                <li>Regular security assessments</li>
                <li>Strict access controls</li>
                <li>Continuous tracking</li>
                <li>Secure backup structures</li>
              </ul>
            </section>

            <section className="legal-page__section">
              <h2>5. Your Rights</h2>
              <p>
                You have the right to:
              </p>
              <ul>
                <li>Access your private statistics</li>
                <li>Request records corrections</li>
                <li>Delete your account</li>
                <li>Opt out of communications</li>
                <li>Export your data</li>
              </ul>
            </section>

            <section className="legal-page__section">
              <h2>6. Age Restrictions</h2>
              <p>
                Our platform is strictly for users 19 years and older. We do not knowingly collect information from minors. 
                If we discover we have collected information from someone under 19, we will promptly delete it.
              </p>
            </section>

            <section className="legal-page__section">
              <h2>7. Contact Us</h2>
              <p>
                For privacy-related inquiries, please contact our Privacy Officer at:
              </p>
              <p>Email: privacy@freespinmaple.com</p>
              <p>Address: 174 Maple Avenue, Toronto, ON M5H 2N6, Canada</p>
            </section>

            <section className="legal-page__section">
              <h2>8. Policy Updates</h2>
              <p>
                We may update this policy periodically. We will notify you of significant changes via email or platform notifications. 
                Continued use of our services after changes indicates acceptance of the updated policy.
              </p>
            </section>
          </div>
        </div>
      </div>
    </section></>
  );
};

export default Privacy;
