// src/components/home/FeaturesSection/FeaturesSection.jsx
import features1 from '../../../assets/images/features1.webp'
import features2 from '../../../assets/images/features2.webp';
import features3 from '../../../assets/images/features3.webp';

import './FeaturesSection.scss';

const FeaturesSection = () => {
  const features = [
    {
      image: features1,
      title: 'Premium Game Collection',
      description: 'Explore our curated choice of fantastic social casino video games together with Jackpot Pearl, Max the Winner, Jackpot Multi and extra. Each game offers particular functions and beautiful visuals.'
    },
    {
      image: features2,
      title: 'Safe & Secure Platform',
      description: 'Play with whole peace of mind on our secure platform. We use superior encryption to defend your records and on the grounds that we do not deal with actual money, you could recognition purely on entertainment.'
    },
    {
      image: features3,
      title: 'Regular Rewards',
      description: 'Never run out of play credit with our beneficiant reward machine. Earn digital money, achievements, special occasions and promotional gives to maintain the amusing going.'
    }
  ];

  return (
    <section className="features-section">
      <div className="container">
        <div className="features-section__header">
          <h2 className="features-section__title">
            EXPERIENCE THE THRILL
            <span>OF PREMIUM SOCIAL GAMING</span>
          </h2>
        </div>

        <div className="features-section__grid">
          {features.map((feature, index) => (
            <div key={index} className="features-section__card">
              <div className="features-section__image">
                <img src={feature.image} alt={feature.title} />
              </div>
              <div className="features-section__content">
                <h3 className="features-section__card-title">{feature.title}</h3>
                <p className="features-section__description">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;