import { Helmet } from 'react-helmet-async';

const SEO = ({ title, description }) => {
  const baseTitle = 'FreeSpinMaple';
  
  return (
    <Helmet>
      <title>{title ? `${title} | ${baseTitle}` : baseTitle}</title>
      {description && <meta name="description" content={description} />}
    </Helmet>
  );
};

export default SEO;