// src/pages/Contact/Contact.jsx
import SEO from '../../components/common/SEO';
import './Contact.scss';

const Contact = () => {
  return (
    <>
    <SEO 
        title="Contact Us"
        description="Get in touch with FreeSpinMaple support team"
      />
    <section className="contact-page">
      <div className="container">
        <div className="contact-page__content">
          <h1 className="contact-page__title">Get in Touch</h1>
          
          <div className="contact-page__info">
            <p className="contact-page__text">
            Have questions about FreeSpinMaple? Our devoted help group is here to assist make your social gaming 
              enjoy outstanding. Whether you want technical help or have popular inquiries, we are only a 
              message away.
            </p>

            <div className="contact-page__details">
              <div className="contact-page__group">
                <h3>Contact Support</h3>
                <a href="mailto:support@freespinmaple.com" className="contact-page__link">
                  support@freespinmaple.com
                </a>
                <p>Response time: Within 24 hours</p>
              </div>

              <div className="contact-page__group">
                <h3>Customer Service Hours</h3>
                <p>Monday - Friday: 8:00 AM - 8:00 PM (EST)</p>
                <p>Saturday & Sunday: 9:00 AM - 5:00 PM (EST)</p>
              </div>

              <div className="contact-page__group">
                <h3>Our Office</h3>
                <p>174 Maple Avenue</p>
                <p>Toronto, ON M5H 2N6</p>
                <p>Canada</p>
              </div>
            </div>

            <div className="contact-page__note">
              <p>
              For the fastest reaction, please include your username (if applicable) and offer particular data 
                approximately your inquiry. We strive to clear up all issues right away and correctly. For pressing technical 
                support, our team is to be had at some stage in prolonged hours.
              </p>
              <p className="contact-page__disclaimer">
                Note: This is a social casino gaming platform. We do not offer real-money gambling services. All games 
                are for entertainment purposes only.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default Contact;