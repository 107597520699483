// src/pages/Home.jsx
import HeroSection from '../components/home/HeroSection/HeroSection';
import AboutSection from '../components/home/AboutSection/AboutSection';
import WhyPlaySection from '../components/home/WhyPlaySection/WhyPlaySection';
import GamesSection from '../components/home/GamesSection/GamesSection';
import FaqSection from '../components/home/FaqSection/FaqSection';
import FeaturesSection from '../components/home/FeaturesSection/FeaturesSection';
import SEO from '../components/common/SEO';

const Home = () => {
  return (
    <div className="home">
      <SEO 
        title="FreeSpinMaple - Social Casino Games"
        description="Your free and entertaining social casino"
      />
      <HeroSection />
      <AboutSection />
      <GamesSection />
      <WhyPlaySection />
      <FaqSection />
      <FeaturesSection />
    </div>
  );
};

export default Home;