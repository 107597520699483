// src/components/home/GamesSection/GamesSection.jsx
import React from 'react';
import GameCard from './GameCard/GameCard';
import { GAMES_CONFIG } from '../../../config/games';
import './GamesSection.scss';

const GamesSection = () => {
  return (
    <section className="games-section" id='game'>
      <div className="container">
        <div className="games-section__header">
          <h2 className="games-section__title">Top Great Games</h2>
          <p className="games-section__description">
            Experience the thrill of real-time casino gaming with the live streaming feature! 
            Play with real dealers, interact with other players, and enjoy an immersive casino 
            experience anytime, anywhere.
          </p>
        </div>
        <div className="games-section__grid">
          {Object.values(GAMES_CONFIG).map(game => (
            <GameCard 
              key={game.id}
              title={game.title}
              image={game.image}
              description={game.description}
              gamePath={`/games/${game.id}`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default GamesSection;