// src/pages/Terms/Terms.jsx
import SEO from '../../components/common/SEO';
import '../LegalPage.scss';

const Terms = () => {
  return (
    <>
   <SEO 
        title="Terms of Service"
        description="Terms of Service and user agreement for Maple Luck Spins CA social casino"
      />
    <section className="legal-page">
      <div className="container">
        <div className="legal-page__content">
          <h1 className="legal-page__title">Terms of Service</h1>
          <div className="legal-page__text">
            <p>Effective Date: March 28, 2024</p>
            
            <section className="legal-page__section">
              <h2>1. Acceptance of Terms</h2>
              <p>
                By accessing or the usage of FreeSpinMaple, you compromise to be bound by these Terms of Service. If you 
                disagree with any part of those phrases, you could now not get right of entry to our services. These phrases represent a 
                legally binding settlement among you and FreeSpinMaple regarding your use of our platform and services.
              </p>
              <p>
                Please study these phrases carefully earlier than using our platform. By growing an account or the usage of any part
                of our offerings, you well known which you have examine, understood, and conform to be sure through these phrases.
                If you are accepting these terms on behalf of a business enterprise or other legal entity, you constitute which you
                have the authority to bind such entity to these phrases.
              </p>
            </section>

            <section className="legal-page__section">
              <h2>2. Social Casino Games</h2>
              <p>
                FreeSpinMaple offers free-to-play social casino games. Our games:
              </p>
              <ul>
                <li>Do no longer provide actual cash gambling or real money prizes</li>
                <li>Are meant for enjoyment purposes only</li>
                <li>Use digital forex without a real-global price</li>
                <li>Are restrained to customers 19 years or older</li>
                <li>May encompass in-recreation purchases the usage of real foreign money</li>
                <li>Provide no possibility to win real money or actual prizes</li>
                <li>Operate purely as enjoyment software</li>
              </ul>
              <p>
                Success at social on line casino gaming has no implication for achievement at real cash playing. Practice or 
                success at social on line casino games does no longer suggest future fulfillment at actual money gambling.
              </p>
              <p>
                Our video games make use of random number mills (RNGs) to determine sport effects. These RNGs are frequently
                examined for fairness and randomness. Game odds and possibilities are designed to decorate amusement
                value and may not replicate actual-global playing odds.
              </p>
            </section>

            <section className="legal-page__section">
              <h2>3. User Conduct</h2>
              <p>
                Users should hold suitable behavior at the same time as using our services. Prohibited sports encompass:
              </p>
              <ul>
                <li>Cheating or exploiting game mechanics</li>
                <li>Using unauthorized 1/3-birthday celebration software program or modifications</li>
                <li>Harassing, bullying, or intimidating other players</li>
                <li>Attempting to get right of entry to unauthorized areas or admin features</li>
                <li>Creating more than one accounts or sharing accounts</li>
                <li>Selling, shopping for, or trading bills</li>
                <li>Using offensive or irrelevant usernames</li>
                <li>Spamming chat or verbal exchange channels</li>
                <li>Impersonating other players or body of workers individuals</li>
                <li>Attempting to govern recreation records or leaderboards</li>
                <li>Engaging in any form of fraudulent interest</li>
                <li>Attempting to reverse engineer or decompile our software</li>
                <li>Using our platform for industrial purposes without authorization</li>
                <li>Violating any applicable laws or rules</li>
              </ul>
              <p>
                Violations of these conduct rules may result in temporary suspension or permanent termination of your
                account, at our sole discretion. We reserve the right to report illegal activities to appropriate
                law enforcement authorities.
              </p>
            </section>

            <section className="legal-page__section">
              <h2>4. Virtual Currency and Purchases</h2>
              <p>
              Our virtual currency system operates under the following conditions:
              </p>
              <ul>
                <li>Virtual currency has no real-world value and cannot be exchanged for real money</li>
                <li>Purchases of virtual currency are final and non-refundable</li>
                <li>We reserve the right to modify virtual currency amounts and prices</li>
                <li>Virtual items and currency are licensed, not sold, to users</li>
                <li>Unused virtual currency expires after 12 months of account inactivity</li>
                <li>Virtual currency balances may be adjusted to correct errors or resolve technical issues</li>
                <li>Purchase limits may be implemented to promote responsible gaming</li>
                <li>Virtual currency transfers between accounts are not permitted</li>
              </ul>
              <p>
                All purchases are processed through authorized payment providers. We are not responsible for any
                charges, fees, or taxes imposed by your payment provider or governmental authorities.
              </p>
            </section>

            <section className="legal-page__section">
              <h2>5. Account Management</h2>
              <p>
                Users are chargeable for:
              </p>
              <ul>
                <li>Maintaining the confidentiality of their account credentials</li>
                <li>All activities that arise underneath their account</li>
                <li>Ensuring their account facts is accurate and up-to-date</li>
                <li>Reporting any unauthorized access or protection breaches</li>
                <li>Maintaining a secure and legitimate e-mail cope with</li>
                <li>Protecting their account recovery records</li>
                <li>Logging out from shared devices</li>
                <li>Regularly reviewing account activity</li>
              </ul>
              <p>
                We strongly advocate enabling -factor authentication and regularly updating your password.
                Users should by no means percentage account credentials or permit others to get right of entry to their account.
              </p>
            </section>

            <section className="legal-page__section">
              <h2>6. Account Termination</h2>
              <p>
                We reserve the proper to suspend or terminate accounts that violate these phrases or interact in 
                irrelevant behavior. Account termination can also occur:
              </p>
              <ul>
                <li>Without earlier word for intense violations</li>
                <li>After warnings for minor infractions</li>
                <li>Upon consumer request for voluntary account closure</li>
                <li>Due to prolonged durations of inactiveness</li>
                <li>For suspected fraudulent pastime</li>
                <li>To observe criminal necessities</li>
                <li>During platform upkeep or updates</li>
              </ul>
              <p>
                Upon termination, users forfeit all digital forex and objects related to their account.
                Terminated accounts cannot be restored, and new bills created through terminated users may be
                robotically suspended.
              </p>
            </section>

            <section className="legal-page__section">
              <h2>7. Intellectual Property</h2>
              <p>
                All content on FreeSpinMaple, along with however not restricted to:
              </p>
              <ul>
                <li>Game photos, art work, and animations</li>
                <li>Sound consequences and track</li>
                <li>Text and user interface elements</li>
                <li>Trademarks and emblems</li>
                <li>Software code and sport mechanics</li>
                <li>Marketing substances and promotional content material</li>
                <li>Website design and format</li>
                <li>Database structures and content material</li>
              </ul>
              <p>
                Is protected by using copyright and different intellectual belongings laws. Users won't reproduction, adjust, 
                distribute, or create by-product works without specific permission. Any unauthorized use may additionally
                result in criminal movement.
              </p>
              <p>
                Users keep ownership of their non-public facts however supply us a international, non-one of a kind license
                to use, method, and show such facts for platform operations and advertising functions.
              </p>
            </section>

            <section className="legal-page__section">
            <h2>eight. Privacy and Data Protection</h2>
              <p>
                Our collection and use of personal facts is governed by means of our Privacy Policy. By using our
                services, you consent to:
              </p>
              <ul>
                <li>Collection of usage records and analytics</li>
                <li>Processing of private records</li>
                <li>Use of cookies and comparable technologies</li>
                <li>Receipt of provider-associated communications</li>
                <li>Sharing of facts with service providers</li>
                <li>Storage of records in steady facilities</li>
              </ul>
              <p>
                We implement enterprise-standard safety features however can't assure absolute protection.
                Users ought to overview our Privacy Policy for targeted information about records coping with practices.
              </p>
            </section>

            <section className="legal-page__section">
              <h2>9. Disclaimers and Limitations</h2>
              <p>
                Our offerings are provided without warranties of any kind. We disclaim:
              </p>
              <ul>
                <li>Responsibility for 1/3-birthday party content material or hyperlinks</li>
                <li>Guarantees of continuous or error-unfastened operation</li>
                <li>Liability for consumer-generated content material</li>
                <li>Warranties of merchantability or fitness</li>
                <li>Responsibility for technical troubles or interruptions</li>
              </ul>
              <p>
                Our legal responsibility is limited to the amount paid through you, if any, for the use of our services within the
                past one year. Some jurisdictions do now not permit sure limitations, so those won't follow to you.
              </p>
            </section>

            <section className="legal-page__section">
            <h2>10. Modifications to Terms</h2>
              <p>
                FreeSpinMaple reserves the proper to modify these phrases at any time. Changes can be powerful 
                straight away upon posting to our website. Continued use of our services after changes constitutes 
                recognition of the modified terms.
              </p>
              <p>
                We will make affordable efforts to notify users of considerable modifications thru:
              </p>
              <ul>
                <li>Email notifications</li>
                <li>In-game bulletins</li>
                <li>Website notifications</li>
                <li>Social media updates</li>
              </ul>
              <p>
                It is your responsibility to regularly overview these terms for updates. If you disagree with any
                modifications, you should stop using our offerings.
              </p>
            </section>

           
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default Terms;