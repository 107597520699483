// src/components/home/AboutSection/AboutSection.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import './AboutSection.scss';
import casinoImage from '../../../assets/images/about-casino.webp';
const AboutSection = () => {
  return (
    <section className="about-section">
      <div className="container">
        <div className="about-section__content">
          <div className="about-section__text">
          <h2 className="about-section__title">Experience Social Gaming at its Best</h2>
            <p className="about-section__description">
              Step into the exciting international of FreeSpinMaple, wherein enjoyment meets community! Our 
              social casino platform brings you the joys of Vegas-style games with none real 
              money dangers. Dive into our considerable series of superbly crafted slots and 
              table video games, compete with pals, climb leaderboards, and be part of a colourful network 
              of gamers who share your passion for gaming. With daily bonuses, unique occasions, 
              and continuous updates, there is always something new to discover. Play responsibly 
              and don't forget - it's all about having fun!
            </p>
            <Link to="/contact" className="about-section__button">
              JOIN THE FUN
            </Link>
          </div>
          <div className="about-section__image">
            <img src={casinoImage} alt="Casino cards and chips" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;