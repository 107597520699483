import jackpotPearlImage from '../assets/images/jackpot-pearl.png';
import maxWinnerImage from '../assets/images/max-winner.png';
import jackpotMultiImage from '../assets/images/jackpot-multi.png';
import jellyHunterImage from '../assets/images/jelly-hunter.png';
import bigMaxDoorImage from '../assets/images/big-max-door.png';
import romeTreasuresImage from '../assets/images/rome-treasures.png';

export const GAMES_CONFIG = {
    jackpotpearl: {
      id: 'jackpotpearl',
      title: "Jackpot Pearl",
      url: "https://loader.swintt-stg.net/staging/gameloader?ts=1712061315268&operatorId=SWINTT-TEST&gameId=15713&languageId=en&client=desktop&funMode=1%22",
      image: jackpotPearlImage,
      description: 'Discover the underwater treasures in Jackpot Pearl!'
    },
    maxthewinner: {
      id: 'maxthewinner',
      url: "https://loader.swintt-stg.net/staging/gameloader?ts=1712061315268&operatorId=SWINTT-TEST&gameId=15714&languageId=en&client=desktop&funMode=1%22",
      title: "Max the Winner",
      image: maxWinnerImage,
      description: 'Join Max on his winning adventure with exciting bonus features and big wins!'
    },
    jackpotmulti: {
      id: 'jackpotmulti',
      url: "https://loader.swintt-stg.net/staging/gameloader?ts=1712061315268&operatorId=SWINTT-TEST&gameId=15716&languageId=en&client=desktop&funMode=1%22",
      title: "Jackpot Multi",
      image: jackpotMultiImage,
      description: 'Multiple ways to win big with this thrilling jackpot slot game!'
    },
    jellyhunter: {
      id: 'jellyhunter',
      url: "https://loader.swintt-stg.net/staging/gameloader?ts=1712061315268&operatorId=SWINTT-TEST&gameId=15719&languageId=en&client=desktop&funMode=1%22",
      title: "Jelly Hunter",
      image: jellyHunterImage,
      description: 'Hunt for colorful jellies in this underwater adventure slot!'
    },
    bigmaxdoor: {
      id: 'bigmaxdoor',
      url: "https://loader.swintt-stg.net/staging/gameloader?ts=1712061315268&operatorId=SWINTT-TEST&gameId=15722&languageId=en&client=desktop&funMode=1%22",
      title: "Big Max Door",
      image: bigMaxDoorImage,
      description: 'Choose the right door and unlock massive prizes with Big Max!'
    },
    hiddentreasuresofrome: {
      id: 'hiddentreasuresofrome',
      url: "https://loader.swintt-stg.net/staging/gameloader?ts=1712061315268&operatorId=SWINTT-TEST&gameId=15723&languageId=en&client=desktop&funMode=1%22",
      title: "Hidden Treasures of Rome",
      image: romeTreasuresImage,
      description: 'Explore ancient Rome and uncover hidden treasures in this epic slot adventure!'
    }
  };