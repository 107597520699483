// src/components/home/WhyPlaySection/WhyPlaySection.jsx
import React from 'react';
import { Shield, Dice6, Trophy, Coins } from 'lucide-react';
import './WhyPlaySection.scss';

const WhyPlaySection = () => {
  const benefits = [
    {
      icon: <Shield />,
      title: "Licensed & Secure Platform",
      description: "We prioritize your security with advanced encryption generation, strict privateness regulations, and responsible gaming equipment. Our platform adheres to the highest enterprise standards to ensure a secure and truthful gaming surroundings for all gamers."    },
    {
      icon: <Dice6 />,
      title: "Premium Game Collection", 
      description: "We prioritize your security with advanced encryption technology, strict privacy guidelines, and responsible gaming tools. Our platform adheres to the highest industry standards to make sure a secure and straightforward gaming environment for all players."    },
    {
      icon: <Trophy />,
      title: "Generous Reward System",
      description: "Enjoy our comprehensive rewards application providing day by day bonuses, hourly rewards, achievement badges, and special event prizes. Level up your gaming revel in with digital forex rewards that preserve the exhilaration going with out spending actual cash."    },
    {
      icon: <Coins />,
      title: "100% Free Entertainment",
      description: "Experience the thrill of casino gaming with none economic danger. Our platform is absolutely loose to play, supplying limitless enjoyment price via our social gaming model. Practice your strategies, compete with friends, and revel in the genuine casino environment for free."    }
  ];

  return (
    <section className="why-play-section">
      <div className="container">
        <div className="why-play-section__content">
          <div className="why-play-section__info">
            <h2 className="why-play-section__title">
              Why Choose
              <br />
              FreeSpinMaple
            </h2>
            <p className="why-play-section__description">
              Step into a global of top class social casino amusement wherein excitement meets 
              accountable gaming, and every spin brings natural enjoyment without monetary risk.
            </p>
            <p className="why-play-section__subdescription">
              We've created an unparalleled social gaming destination that combines the quality aspects 
              of conventional casinos with revolutionary social capabilities. Enjoy beautiful games powered 
              by using current era, participate in thrilling tournaments, and connect with a 
              worldwide community of gamers - all in a safe, regulated environment. While our games 
              are designed to copy the proper casino experience, we remind our gamers that 
              achievement in social casino video games does not imply future success in real-cash gambling.
            </p>
          </div>

          <div className="why-play-section__benefits">
            {benefits.map((benefit, index) => (
              <div key={index} className="why-play-section__benefit">
                <div className="why-play-section__benefit-icon">
                  {benefit.icon}
                </div>
                <h3 className="why-play-section__benefit-title">
                  {benefit.title}
                </h3>
                <p className="why-play-section__benefit-description">
                  {benefit.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyPlaySection;